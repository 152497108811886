<template>
  <div class="footer-container">
    <div class="subscribe-container">
      <div class="top-container">
        <!-- <div class="newsletter">
          <h4>
            Subscribe to get our promos, news,
            <br />and the latest marketing kit
          </h4>
          <div class="submit-email">
            <input
              type="email"
              v-model="email"
              :placeholder="error"
              :class="[red ? 'emailError' : '']"
            />
            <button class="submit" @click.prevent="submit">Submit</button>
          </div>
        </div> -->

        <div class="kanan">
          <div class="summary-container">
            <div class="higo-icon">
              <div id="logo-1">
              <img src="@/assets/HIGO-Logo-25-min.png" alt />
              <p class="address">PT HIGO FITUR INDONESIA <br> Plaza Kedoya Elok, Lantai 3 Blok DA No. 3-4, Jakarta Barat 11520</p>
              <div class="contact">
                  <div>E: info@higo.id</div>
                  <div>P: (021) 5806860</div>
              </div>
            </div>
            </div>
            <div class="group-link">
              <router-link to="/about-us">About Us</router-link>
              <a class="anchor" @click.prevent="goCareers">Career</a>
              <a class="anchor" @click="goToBlog">Blog</a>
              <router-link class="top-space-terms" to="/terms-of-use-higospot">Terms & Conditions</router-link>
              <router-link class="top-space-privacy" to="/privacy-policy-higospot">Privacy & Policy</router-link>
            </div>
          </div>
        </div>
      </div>
      <hr style="margin-top:10px;">
      <div class="bottom-banget">
        <div class="higo-fitur">
          <p style="font-family: 'Arial', 'Helvetica', sans-serif; font-size: 13px;">
            &copy; PT Higo Fitur Indonesia 2024
          </p>
        </div>
        <div class="icon">
          <a
            href="https://www.linkedin.com/company/pt-higo-fitur-indonesia/"
            target="blank"
            class="sosmed"
          >
            <img src="@/assets/linkedin-min.png" alt="linkedin" />
          </a>
          <a href="https://www.facebook.com/HIGOSPOTINDONESIA/" target="blank" class="sosmed">
            <img src="@/assets/facebook-w (1)-min.png" alt="facebook" />
          </a>
          <a href="https://www.instagram.com/higo.id/?hl=en" target="blank" class="sosmed">
            <img src="@/assets/instagram-logo-w (1)-min.png" alt="instagram" />
          </a>
          <a href="https://twitter.com/HIGO_spot" target="blank" class="sosmed">
            <img src="@/assets/twitter-w-min.png" alt="twitter" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data: function () {
    return {
      email: '',
      error: 'Enter Your Email...',
      red: false,
      windowWidth: 0
    }
  },
  methods: {
    goCareers () {
      sessionStorage.setItem('from-url-career', 'footer' + ' ' + window.location.href)
      this.$router.push('/careers')
    },
    submit () {
      if (this.email === '') {
        this.error = 'Email must be required!'
        this.red = true
        return
      }

      if (!this.checkEmail(this.email)) {
        this.resetForm()
        this.error = 'Please fill valid email!'
        this.red = true
        return
      }

      let formData = new FormData()

      formData.append('email', this.email)
      formData.append('from_url', window.location.href)
      axios({
        url: 'https://apiw.higo.id/web-subscribecontact',
        method: 'post',
        data: formData
      })
        .then(response => {
          this.red = false
          this.resetForm()
          this.$toast.open({
            message: `Success\nThanks for Submitting`,
            type: 'success',
            position: 'bottom-right',
            duration: 2500,
            dismissible: true
          })
        })
        .catch(err => {
          this.error = 'Server not responding...'
          this.resetForm()
        })
    },
    goToBlog () {
      const lang = sessionStorage.getItem('usr_dis_lang')
      location.replace(`https://blog.higo.id?lang=${lang}`)
    },
    resetForm () {
      this.email = ''
    },
    checkEmail (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }
  },
  created () {
    this.windowWidth = window.innerWidth
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth
      })
    })
  }
}
</script>

<style scoped>
.footer-container {
  width: 100%;
  min-height: auto;
  background-color: #253BE2;
  color: white;
}

.subscribe-container {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  padding: 30px 0;
  background-color: #253BE2;
  height: auto;
}
.higo-icon .address{
  width: 320px;
  font-size:16px;
  padding-bottom: 20px;
  padding-top: 10px;
}

.higo-icon .contact {
  display: flex;
  gap: 24px;
}

.newsletter {
  width: 40%;
  padding-top: 30px;
}

.summary-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.higo-icon img {
  width: 100px;
}

.group-link {
  display: flex;
  height: 100%;
  flex-direction: row;
}

.group-link a {
  margin-right: 20px;
}

.group-link a:last-child {
  margin-right: 0px;
}

.top-space-terms, .top-space-privacy {
  margin-right: 20px;
}

.top-container {
  height: 70%;
}

.anchor {
  cursor: pointer;
}

.bottom-banget {
  height: 30%;
}

.submit-email {
  width: 90%;
  height: 30px;
  margin-top: 20px;
  display: flex;
}

.submit-email input {
  height: 100% !important;
  border-radius: 50px 0 0 50px;
  padding-left: 1rem;
}

.icon {
  width: 25%;
  height: auto;
  display: flex;
  /* justify-content: space-between; */
  gap: 16px;
  justify-content: end;
}

.icon img {
  /* width: 100%; */
  height: auto;
  width:27px
}

.sosmed img:hover {
  cursor: pointer !important;
}

.emailError::placeholder {
  color: red;
}

/* .subscribe-container, .summary-container {
    width: 50%;
    height: 100%;
  } */

/* .newsletter {
    display: flex;
    height: 80%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    margin-left: 10rem;
  } */

.newsletter h4 {
  font-size: 1rem;
}

/* .submit-email {
    margin-top: 20px;
    width: 400px;
    height: 30px;
    background-color: white;
    padding-left: 20px;
    border-radius: 50px;
  } */

.submit-email input {
  height: auto;
  border: none;
  outline: none;
  font-size: 1.02rem;
  width: 75%;
}

.submit-email button {
  border-radius: 0 50px 50px 0;
  height: 100%;
  width: 25%;
  border: none;
  font-size: 1.02rem;
  background-color: #5cdcbd;
  color: white;
  font-weight: 600;

  transition: 0.3s;
}

.submit-email button:hover {
  background-color: #48b097;
  cursor: pointer;
}

.summary-container a {
  color: white;
  /*font-size: 14px;*/
  text-decoration: none;
}

.router-link-active {
  color: white !important;
}

.bottom-banget {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 20%;
  margin-top: 40px;
}

@media screen and (min-width: 320px) and (max-width: 367px) {
  .higo-icon img {
    width: 80px;
    margin-bottom: 30px;
    margin-top: 40px;
  }

  .group-link {
    margin-bottom: 50px;
  }

  .group-link a {
    margin-right: 12px;
  }

  .top-space-terms, .top-space-privacy {
    margin-top: 20px;
    margin-right: 12px;
  }

  .bottom-banget {
    margin-bottom: 40px;
  }

  .icon {
    width: 160px !important;
  }

  .icon a {
    margin-right: 25px !important;
  }

  .icon a:last-child {
    margin-right: 0px !important;
  }

  .subscribe-container {
    padding: 0 20px;
  }
}

@media screen and (min-width: 368px) and (max-width: 449px) {
  .higo-icon img {
    width: 80px;
    margin-bottom: 30px;
    margin-top: 40px;
  }

  .group-link {
    margin-bottom: 50px;
  }

  .group-link a{
    margin-right: 32px !important;
  }

  .top-space-terms, .top-space-privacy {
    margin-top: 20px;
    margin-right: 12px;
  }

  .bottom-banget {
    margin-bottom: 40px;
  }

  .icon {
    width: 200px;
  }

  .icon a:last-child {
    margin-right: 0px !important;
  }

  .subscribe-container {
    padding: 0 20px;
  }
}

@media screen and (min-width: 450px) and (max-width: 493px) {
  .higo-icon img {
    width: 80px;
    margin-bottom: 30px;
    margin-top: 40px;
  }

  .top-space-privacy {
    margin-top: 20px;
  }

  .group-link {
    margin-bottom: 50px;
  }

  .icon {
    width: 200px;
  }

  .bottom-banget {
    margin-bottom: 40px;
  }

  .subscribe-container {
    padding: 0 20px;
  }
}

@media screen and (min-width: 494px) and (max-width: 573px) {
  .higo-icon img {
    width: 80px;
    margin-bottom: 30px;
    margin-top: 40px;
  }

  .top-space-privacy {
    margin-top: 0px;
  }

  .group-link {
    margin-bottom: 50px;
  }

  .group-link a{
    margin-right: 32px !important;
  }

  .top-space-privacy {
    margin-top: 20px;
  }

  .bottom-banget {
    margin-bottom: 40px;
  }

  .subscribe-container {
    padding: 0 20px;
  }
}

@media screen and (min-width: 574px) and (max-width: 593px) {
  .higo-icon img {
    width: 80px;
    margin-bottom: 30px;
    margin-top: 40px;
    margin-right: 20px;
  }

  .top-space-privacy {
    margin-top: 0px;
  }

  .group-link {
    margin-top: 0px;
    margin-bottom: 50px;
  }

  .bottom-banget {
    margin-top: 20px;
  }

  .subscribe-container {
    padding: 0 28px;
  }
}

@media screen and (min-width: 594px) and (max-width: 598px) {
  .higo-icon img {
    width: 80px;
    margin-bottom: 30px;
    margin-top: 40px;
    margin-right: 10px;
  }

  .icon {
    width: 200px;
  }

  .summary-container {
    margin-bottom: 30px;
  }
  .bottom-banget {
    margin-bottom: 40px;
  }

  .subscribe-container {
    padding: 0 28px;
  }
}

@media screen and (min-width: 599px) and (max-width: 670px) {

  .summary-container {
    margin-bottom: 10px;
  }
  .top-space-privacy {
    margin-top: 0px;
  }

  .group-link {
    margin-bottom: 60px;
  }

  .group-link a{
    margin-right: 30px !important;
  }

  .bottom-banget {
    margin-top: 0px;
  }

  .subscribe-container {
    padding: 0 28px;
  }
}

@media screen and (min-width: 671px) and (max-width: 766px) {
  .top-space-privacy {
    margin-top: 0px;
  }

  .bottom-banget {
    margin-top: 30px;
  }

  .subscribe-container {
    padding: 0 28px;
  }
}

@media screen and (min-width: 767px) {
  .subscribe-container {
    padding: 0 28px;
  }
}

@media screen and (max-width: 767px) {
  .higo-icon .address {
    font-size: 12px;
    padding-bottom: 5px;
  }
  .higo-icon .contact {
    font-size: 10px;
    padding-bottom: 10px;
  }
  .subscribe-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    /*margin-top: 2vh;*/
  }

  .top-container {
    flex-direction: column;
  }

  .newsletter {
    text-align: center;
    width: 100%;
  }

  .newsletter h4 {
    font-size: 12px;
  }

  .submit-email {
    margin-top: 1vh;
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .submit-email input {
    width: 70%;
    height: 100%;
    padding-left: 10px;
    border-radius: 50px 0 0 50px;
    border: none;
  }

  .summary-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;
    /*padding: 0 10px;*/
  }

  .kanan {
    width: 100%;
    padding: 0;
  }

  .summary-container a {
    font-size: 13px;
  }

  .higo-icon img {
    width: 80px !important;
    margin-bottom: 30px;
    margin-top: 40px;
  }

  .group-link {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 2vh;
  }

  hr {
    display: none;
  }

  .bottom-banget {
    padding-top: 20px;
    border-top: 1px solid white;
    display: flex;
    /* justify-content: space-between; */
    gap:20px;
    align-items: center;
    padding-top: 35px;
    margin-bottom: 40px;
    flex-wrap: wrap;
  }

  .higo-fitur p {
    font-size: 11px !important;
  }

  .icon {
    display: flex;
    justify-content: space-between;
    width: 200px;
  }

  .icon a {
    margin-right: 40px;
  }

  .icon a:last-child {
    margin-right: 0px;
  }

  .footer-container {
    height: auto;
  }

  /*.subscribe-container {
    padding: 0 28px;
  }*/
}

@media screen and (min-width: 768px) and (max-width: 867px) {
  .subscribe-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 30px;
    padding-right: 32px;
    padding-left: 32px;
    /*margin-top: 2vh;*/
  }

  .top-container {
    flex-direction: column;
  }

  .newsletter {
    text-align: center;
    width: 100%;
  }

  .newsletter h4 {
    font-size: 12px;
  }

  .submit-email {
    margin-top: 1vh;
    width: 80%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
  }

  .submit-email input {
    width: 70%;
    height: 100%;
    padding-left: 10px;
    border-radius: 50px 0 0 50px;
    border: none;
  }

  .summary-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    /*width: 80%;
    margin: 1% auto;*/
  }

  .kanan {
    width: 100%;
    padding: 0;
  }

  .summary-container a {
    font-size: 13px;
  }

  .group-link {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 5vh;
    margin-top: 25px;
  }

  .group-link a {
    margin-right: 34px;
  }

  hr {
    display: none;
  }

  .higo-icon img{
    width: 80px !important;
    margin-top: 30px;
  }

  .bottom-banget {
    margin-top: 40px !important;
    padding-top: 20px;
    border-top: 1px solid white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 35px;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .icon {
    display: flex;
    justify-content: space-between;
    width: 200px;
  }

  .icon a {
    margin-right: 40px;
  }

  .icon a:last-child {
    margin-right: 0px;
  }

  .footer-container {
    height: auto;
  }
}

@media screen and (min-width: 868px) and (max-width: 991px) {
  .subscribe-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 20px;
    padding-top: 30px;
    /*margin-top: 2vh;*/
  }

  .top-container {
    flex-direction: column;
  }

  .newsletter {
    text-align: center;
    width: 100%;
  }

  .newsletter h4 {
    font-size: 12px;
  }

  .submit-email {
    margin-top: 1vh;
    width: 80%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
  }

  .submit-email input {
    width: 70%;
    height: 100%;
    padding-left: 10px;
    border-radius: 50px 0 0 50px;
    border: none;
  }

  .summary-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 1% auto;
  }

  .summary-container a {
    font-size: 13px;
  }
  .kanan {
    width: 100%;
    padding: 0;
  }

  .group-link {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 5vh;
    margin-top: 30px;
  }

  .higo-icon img{
    width: 80px !important;
    margin-top: 30px;
  }

  .group-link a {
    margin-right: 15px;
  }

  .bottom-banget {
    margin-top: 40px !important;
    padding-top: 20px;
    border-top: 1px solid white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 40px;
  }

  hr {
    display: none;
  }

  .icon {
    display: flex;
    justify-content: space-between;
    width: 200px;
  }

  .icon a {
    margin-right: 40px;
  }

  .icon a:last-child {
    margin-right: 0px;
  }

  .subscribe-container {
    padding: 0 35px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .subscribe-container {
    padding: 0 40px;
  }

  .higo-icon img {
    width: 100px;
    margin-bottom: 30px;
    margin-top: 40px;
  }

  .summary-container a {
    font-size: 15px;
  }

  .top-container {
    margin-bottom: 20px;
  }

  .bottom-banget {
    margin-top: 20px;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 40px;
  }

  .icon {
    display: flex;
    height: auto;
    justify-content: space-between;
  }

  .icon a {
    margin-right: 40px;
  }

  .icon a:last-child {
    margin-right: 0px;
  }
}

@media screen and (min-width: 1200px) {
  .subscribe-container {
    padding: 0 60px;
  }

  .higo-icon img {
    width: 100px;
    margin-bottom: 30px;
    margin-top: 40px;
  }

  .summary-container a {
    font-size: 16px;
  }

  .top-container {
    margin-bottom: 20px;
  }

  .bottom-banget {
    margin-top: 20px;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
  }
}
</style>
